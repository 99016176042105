<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <!-- <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="姓名">
        <el-input style="width: 240px" clearable v-model="searchData.name" size="small" placeholder="请输入"></el-input>
      </el-form-item>

      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form> -->

    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="title" label="名称" min-width="120" align="left">
        <template slot-scope="{ row }">
          <el-tag type="primary" size="mini">{{ row.title }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="level" label="level" min-width="120" align="left" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="price" label="价格/月" min-width="120" align="left" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="id" label="背景图" min-width="120" align="left">
        <template slot-scope="{ row }">
          <el-image style="width: 100px; height: 50px; border-radius: 5px" :src="row.bgUrl" fit="cover">
            <div slot="error" style="background: #f5f5f5; height: 50px"></div>
          </el-image>
        </template>
      </el-table-column>

      <el-table-column align="left" label="操作" width="150" show-overflow-tooltip fixed="right">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false" @click="handleDetail(row)">详情</el-link>
          <!-- <el-link style="margin-left: 10px" type="danger" :underline="false">删除</el-link> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.pageSize" @pagination="getList" /> -->
    <!-- 详情 -->
    <el-drawer size="50%" :visible.sync="show_detail" direction="rtl">
      <div class="vip-info">
        <el-descriptions direction="vertical" :column="4" border>
          <el-descriptions-item label="会员名称">
            <el-tag type="primary" size="mini">{{ detail.title }}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="价格/月">{{ detail.price }}</el-descriptions-item>
          <el-descriptions-item label="背景图" :span="2">
            <el-image style="width: 100px; height: 50px; border-radius: 5px" :src="detail.bgUrl" fit="cover">
              <div slot="error" style="background: #f5f5f5; height: 50px"></div>
            </el-image>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="tequan">
        <admin-title title="特权列表"></admin-title>
        <div class="list">
          <div class="item" v-for="(item, i) in detail.privilegeList" :key="i">
            <el-image style="width: 43px; height: 43px; border-radius: 50%" :src="item.icon" fit="cover">
              <div slot="error" style="background: #f5f5f5; height: 43px"></div>
            </el-image>
            <span>{{ item.privilegeTitle }}</span>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        pageSize: 10
      },
      total: 0,
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    async getList() {
      this.list = await getListAPI(this.searchData)
    },
    handleDetail(row) {
      this.detail = row
      this.show_detail = true
    },

    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .vip-info {
    padding: 0 20px;
  }
  .tequan {
    padding: 20px;
    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(135deg, #0e0e0e 0%, #3a3a3a 100%);
        border-radius: 10px;
        padding: 10px;
        color: #ffffff;
        font-size: 16px;
        span {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
